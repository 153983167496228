import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import avo from 'analytics/avo';

import ProductCardContext from 'data-hooks/context/ProductCardContext';
import mergeProductAndSelectedOptionData from './helpers/mergeProductAndSelectedOptionData';
import filterPropsNotNeededForLayout from './helpers/filterPropsNotNeededForLayout';
import ProductCardStaticUI from './ProductCardStaticUI';

// This provides a new simple interface to render a ProductCard. As we
// gradually move to this component, we should be able to identify which props
// in the main ProductCard component are unnecessary. After migration, we
// should be able to remove all unnecessary props and state from the main
// ProductCard, then we can merge this component into the ProductCard.
const ProductCardStatic = ({
  analyticsComponentName = avo.Component.PRODUCT_CARD,
  campaign,
  enableMobileCarousel,
  filterStateKey = '',
  hidden,
  id,
  initialOptionId = null,
  isClearance,
  isFavorited,
  layout = 'full',
  moreVariantsCount,
  name,
  onClick,
  onDisplayInShowroomSlug,
  path,
  position,
  productFamily,
  productLabel,
  productType,
  quickship,
  secondaryImage,
  secondaryImageCropData,
  slug,
  variantsToShow,
  variantsTotal,
  isHotspotCard,
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState(initialOptionId);
  const resetToInitialOptionId = useCallback(() => {
    setSelectedOptionId(prevSelectedOptionId => {
      if (prevSelectedOptionId !== initialOptionId) {
        return initialOptionId;
      }

      return prevSelectedOptionId;
    });
  }, [initialOptionId]);

  // If sort or filtering options change, reset the selected option to the
  // initial option.
  useEffect(() => {
    resetToInitialOptionId();
  }, [resetToInitialOptionId, filterStateKey, name]);

  // Create an object that contains all data required to render a ProductCard
  // for the selected option and layout.
  // We use the useMemo hook to ensure that ProductCard components are only
  // re-rendered when the incoming props or selected option changes.
  const productCardData = useMemo(() => {
    // Default to first option
    const selectedOption =
      variantsToShow?.find(
        option => !!selectedOptionId && option.id === selectedOptionId
      ) ?? variantsToShow?.[0];

    const mergedData = mergeProductAndSelectedOptionData({
      campaign,
      enableMobileCarousel,
      id,
      isClearance,
      isFavorited,
      moreVariantsCount,
      name,
      onDisplayInShowroomSlug,
      path,
      position,
      productFamily,
      productLabel,
      productType,
      quickship,
      secondaryImage,
      secondaryImageCropData,
      selectedOption,
      slug,
      variantsToShow,
      variantsTotal,
    });

    // Only include the data that is necessary for the specified layout, so that
    // we can conditionally render elements when the data is provided.
    const filteredProductData = filterPropsNotNeededForLayout(
      mergedData,
      layout
    );

    const dataWithActions = {
      ...filteredProductData,
      campaign,
      isClearance,
      isFavorited,
      position,
      productFamily,
      productType,
      selectedOptionId: selectedOption?.id,
      setSelectedOptionId,
    };

    return dataWithActions;
  }, [
    campaign,
    enableMobileCarousel,
    id,
    isClearance,
    isFavorited,
    layout,
    moreVariantsCount,
    name,
    onDisplayInShowroomSlug,
    path,
    position,
    productFamily,
    productLabel,
    productType,
    quickship,
    secondaryImage,
    secondaryImageCropData,
    selectedOptionId,
    slug,
    variantsToShow,
    variantsTotal,
  ]);

  return (
    <ProductCardContext.Provider value={productCardData}>
      <ProductCardStaticUI
        analyticsComponentName={analyticsComponentName}
        hidden={hidden}
        enableMobileCarousel={enableMobileCarousel}
        onClick={onClick}
        isHotspotCard={isHotspotCard}
        layout={layout}
      />
    </ProductCardContext.Provider>
  );
};

ProductCardStatic.propTypes = {
  analyticsComponentName: PropTypes.string,
  campaign: PropTypes.string,
  enableMobileCarousel: PropTypes.bool,
  filterStateKey: PropTypes.string,
  hidden: PropTypes.bool,
  id: PropTypes.number.isRequired,
  initialOptionId: PropTypes.string,
  isHotspotCard: PropTypes.bool,
  isClearance: PropTypes.bool,
  isFavorited: PropTypes.bool,
  layout: PropTypes.oneOf([
    'full',
    'mini',
    'minimal',
    'minimal-with-attributes',
    'minimal-with-showroom',
  ]),
  moreVariantsCount: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onDisplayInShowroomSlug: PropTypes.string,
  path: PropTypes.string,
  position: PropTypes.number,
  productFamily: PropTypes.string,
  productLabel: PropTypes.string,
  productType: PropTypes.string,
  quickship: PropTypes.bool,
  secondaryImage: PropTypes.string,
  secondaryImageCropData: PropTypes.object,
  slug: PropTypes.string,
  variantsToShow: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      isOutOfStock: PropTypes.bool,
      label: PropTypes.string,
      minPrice: PropTypes.number,
      originalPrice: PropTypes.number,
      path: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quickship: PropTypes.bool,
      sku: PropTypes.string,
    })
  ),
  variantsTotal: PropTypes.number,
};

export default ProductCardStatic;
