import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { GET_FAVORITED_PRODUCT_IDS } from 'mocks/queries';
import IconHeart from 'components/icons/iconHeart';
import { colorGrayLight2, colorRed } from 'styles/layout/colors';
import useProductCardContext from 'data-hooks/useProductCardContext';
import useUserData from 'data-hooks/useUserData';
import useSetFavoriteModalState from 'global-state/favoriteModalState/useSetFavoriteModalState';
import { FAVORITE_PRODUCT_V2 } from 'mocks/mutations';

const ProductCardFavoriteButtonNew = () => {
  const product = useProductCardContext();
  const isFavorited = product?.isFavorited ?? false;

  const [toggleFavorite] = useMutation(FAVORITE_PRODUCT_V2, {
    optimisticResponse: {
      userFavoriteActionV2: {
        id: product.id,
        status: !isFavorited,
        __typename: 'FavResponseV2',
      },
    },
    update: (cache, { data: { userFavoriteActionV2 } }) => {
      const data = cache.readQuery({ query: GET_FAVORITED_PRODUCT_IDS }) || { favoritedProductIds: [] };
      const { productIds } = data;

      const newFavoritedProductIds = userFavoriteActionV2?.status
        ? [...productIds, userFavoriteActionV2?.id]
        : productIds.filter(id => id !== userFavoriteActionV2?.id);

      cache.writeQuery({
        query: GET_FAVORITED_PRODUCT_IDS,
        data: { productIds: newFavoritedProductIds },
      });
    },
    refetchQueries: [{ query: GET_FAVORITED_PRODUCT_IDS }],
  });
  
  const { isLoggedIn } = useUserData();
  const setFavoriteModalState = useSetFavoriteModalState();

  const addProductToFav = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!product.id) {
      return;
    }
    if (!isLoggedIn) {
      setFavoriteModalState({ productId: product.id });
    } else {
      toggleFavorite({ variables: { productID: product.id } });
    }
  }, [product, isLoggedIn, setFavoriteModalState, toggleFavorite]);

  return (
    <button
      type="button"
      className={classNames(
        'w-9 h-9 rounded-full flex items-center justify-center',
        {
          'opacity-100 bg-red': isFavorited,
          'opacity-0 bg-white group-hover/product-card:opacity-100': !isFavorited,
        }
      )}
      onClick={addProductToFav}
    >
      <IconHeart
        className={classNames('w-[0.875rem] ', {
          'animate-heartbeat': isFavorited,
        })}
        color={isFavorited ? colorRed : colorGrayLight2}
        fill={isFavorited ? 'white' : colorGrayLight2}
      />
    </button>
  );
};

export default ProductCardFavoriteButtonNew;