import getProductCardLayoutFlags from './getProductCardLayoutFlags';

const filterPropsNotNeededForLayout = (
  {
    id,
    image,
    isOutOfStock,
    label,
    minPrice,
    moreVariantsCount,
    name,
    onDisplayInShowroomSlug,
    originalPrice,
    otherAttributes,
    path,
    price,
    productFamily,
    productLabel,
    productPath,
    productType,
    quickship,
    secondaryImage,
    secondaryImageCropData,
    sku,
    slug,
    upholsteryThumbnail,
    variantsToShow,
  },
  layout = 'full'
) => {
  // Map the "layout" prop to booleans that are more readable
  const {
    showAttributes,
    showProductLabel,
    showShowroomInfo,
    showStockInfo,
    showSwatches,
  } = getProductCardLayoutFlags(layout);

  return {
    id,
    image,
    minPrice,
    name,
    originalPrice,
    path,
    productPath,
    price,
    productFamily,
    productType,
    secondaryImage,
    secondaryImageCropData,
    sku,
    slug,
    ...(showAttributes && {
      label,
      otherAttributes,
      upholsteryThumbnail,
    }),
    ...(showProductLabel && {
      productLabel,
    }),
    ...(showShowroomInfo && {
      onDisplayInShowroomSlug,
    }),
    ...(showStockInfo && {
      isOutOfStock,
      quickship,
    }),
    // Only include variants if there are a minimum of two variants to show
    ...(showSwatches &&
      variantsToShow?.length >= 2 && {
        variantsToShow,
      }),
    // Only include moreVariantsCount and productPath if there are more variants
    ...(showSwatches &&
      moreVariantsCount > 0 && {
        moreVariantsCount,
      }),
  };
};

export default filterPropsNotNeededForLayout;
