import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import useProductCardContext from 'data-hooks/useProductCardContext';
import ProductCardSwatchesImage from './ProductCardSwatchesImage';

const ProductCardSwatchesNewUI = ({
  fabricSwatches = [],
  moreVariantsCount: moreVariantsCountFromProps,
  onProductClick,
  onSwatchHovered,
  productURL = '',
  selectedSwatchIndex,
  setSelectedSwatchIndex,
}) => {
  const productCardContextData = useProductCardContext();
  const history = useHistory();

  // TODO: After we have completed migration to the new product card data
  // format, we should remove this data transformation and use the data directly
  const variantsToShowFromContext = productCardContextData?.variantsToShow?.map(
    option => ({
      ...option,
      fabricImage: option.thumbnail,
      isSelected: option.id === productCardContextData.selectedOptionId,
      key: option.id,
      optionId: option.id,
    })
  );

  const fabricSwatchesCleaned = fabricSwatches
    .filter(swatch => !!swatch)
    .map((swatch, index) => ({
      ...swatch,
      // Normally we avoid using indexes in keys, but this component sometimes
      // receives duplicate fabricId values, so the index is added to make the
      // key unique.
      key: `${swatch.fabricId}-${index}`,
      optionId: index,
      isSelected: index === selectedSwatchIndex,
    }));

  const setSelectedOption =
    productCardContextData?.setSelectedOptionId ?? setSelectedSwatchIndex;

  const swatchesToShow = variantsToShowFromContext ?? fabricSwatchesCleaned;
  const moreVariantsCount =
    productCardContextData?.moreVariantsCount ?? moreVariantsCountFromProps;

  return (
    <div className="z-10 md:items-center md:flex-col">
      <div className="flex flex-row mt-[.4rem] mx-0 mb-0">
        {swatchesToShow.map(
          ({ fabricImage, key, label, isSelected, optionId }) => {
            // E.g. When label is "Aerial Chocolate, Mocha" we show "Aerial
            // Chocolate" if user hovers over swatch.
            const shortLabel = label?.split(',')?.[0] ?? undefined;

            return (
              <div
                key={key}
                className={classNames(
                  "h-9 mx-px my-0 overflow-hidden relative transition-[all_.2s_ease-in] w-[27px] md:w-[35px] before:rounded before:bottom-0 before:h-8 before:left-0 before:m-auto before:absolute before:right-0 before:top-0 before:w-6 before:md:w-8 before:border before:border-solid before:[border-color:#4a4a4a] before:[content:''] before:block before:scale-50 before:opacity-100 before:transition-[all_.2s_ease-in-out] focus:outline-0 before:focus:border-black before:focus:scale-100 before:focus:opacity-100 before:hover:border-black before:hover:scale-100 before:hover:opacity-100 cursor-pointer",
                  {
                    'before:border-black before:scale-100 before:opacity-100': isSelected,
                  }
                )}
                onFocus={() => {
                  onSwatchHovered?.();
                  setSelectedOption(optionId);
                }}
                onMouseOver={() => {
                  onSwatchHovered?.();
                  setSelectedOption(optionId);
                }}
                aria-label={shortLabel}
                title={shortLabel}
                onClick={onProductClick}
                role="presentation"
              >
                <span className="[&_img]:rounded [&_img]:bottom-0 [&_img]:h-8 [&_img]:left-0 [&_img]:m-auto [&_img]:absolute [&_img]:right-0 [&_img]:top-0 [&_img]:w-6 [&_img]:md:w-8 [&_img]:border-2 [&_img]:border-solid [&_img]:border-transparent">
                  <ProductCardSwatchesImage
                    label={shortLabel}
                    src={fabricImage}
                  />
                </span>
              </div>
            );
          }
        )}

        {moreVariantsCount ? (
          <button
            className="items-stretch self-stretch bg-transparent border-0 flex-1 justify-center !no-underline cursor-pointer"
            onClick={() => {
              history.push(
                `${productCardContextData?.productPath ??
                  productURL}?filterBy=all`,
                productCardContextData?.name
                  ? {
                      name: productCardContextData.name,
                    }
                  : undefined
              );
            }}
            type="button"
            tabIndex="0"
            aria-label={`${moreVariantsCount}+ more`}
          >
            <div className="items-center flex flex-col justify-center ml-1 relative h-[29px] w-[30px] after:[content:''] after:block after:absolute after:transition-[all_.2s_linear] before:[content:''] before:block before:absolute before:[background:linear-gradient(90deg,#fdde5c_0,#f8ab5e_16.66667%,#f56a62_33.33333%,#a176c8_50%,#759beb_66.66667%,#65beb3_83.33333%,#70db96)] before:rounded-[3px] before:h-full before:opacity-100 before:transition-[opacity_.2s_ease-in] before:w-full before:z-0 after:bg-white after:rounded-sm after:h-[calc(100%_-_3px)] after:w-[calc(100%_-_3px)] after:z-[1]">
              <div className="text-[.65rem] items-center text-gray-light1 flex pl-[3px] pr-[3px] z-[2] justify-center !leading-[.6rem] flex-col">
                <div>{moreVariantsCount}+</div>
                <div>more</div>
              </div>
            </div>
          </button>
        ) : null}
      </div>
    </div>
  );
};

ProductCardSwatchesNewUI.propTypes = {
  fabricSwatches: PropTypes.arrayOf(PropTypes.object),
  moreVariantsCount: PropTypes.number,
  onProductClick: PropTypes.func,
  onSwatchHovered: PropTypes.func,
  productURL: PropTypes.string,
  selectedSwatchIndex: PropTypes.number,
  setSelectedSwatchIndex: PropTypes.func,
};

export default ProductCardSwatchesNewUI;
